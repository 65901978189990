import { IconBtc, IconGram, IconEtc, IconRvn } from '@/shared/ui/Icons';

export const GRAM_COIN = 'GRAM';
export const BTC_COIN = 'BTC';

export const accountCoins = [
  'BTC',
  'GRAM',
  // 'ETC',
  // 'RVN',
  // 'KAS', // shutodown kaspa
  // 'DNX',
];

export const networkCoins = {
  BTC: 'BTC',
  GRAM: 'TON',
  // ETC: 'ETC',
  // RVN: 'RVN',
}

export const DEFAULT_COIN = accountCoins[0];

export const COINS_MAP = {
  'BTC': {
    name: 'Bitcoin',
    symbol: 'BTC',
    icon: IconBtc,
  },
  'GRAM': {
    name: 'Gram',
    symbol: 'GRAM',
    icon: IconGram,
  },
  // 'ETC': {
  //   name: 'Etc',
  //   symbol: 'ETC',
  //   icon: IconEtc,
  // },
  // 'RVN': {
  //   name: 'Rvn',
  //   symbol: 'RVN',
  //   icon: IconRvn,
  // },
  // 'KAS': {
  //   name: 'Kaspa',
  //   symbol: 'KAS',
  //   icon: IconKaspa,
  // },
  // 'DNX': {
  //   name: 'Dynex',
  //   symbol: 'DNX',
  //   icon: IconDynex,
  // },
};
